import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { RequestModel } from './models/requestModel';
import { ResponseModel } from './models/responseModel';

@Injectable({
  providedIn: 'root',
})
export class TalepService {
  constructor(private httpClient: HttpClient) {}

  Sorgu(request: RequestModel) {
    request.ApiKey = environment.apikey;

    let headers = new HttpHeaders({ 
      'Accept-Language': 'tr-TR',
    });


    if (request.TelefonNo.startsWith('0')) {
      request.TelefonNo = request.TelefonNo.substring(1);
    } 
   
    alert("asadas");
    
    return this.httpClient.post<ResponseModel>(
      'https://api.servispozitif.com/api/Talep/GetCihazDurumSorgu',
      request,
      { headers: headers }
    );
  }
}
