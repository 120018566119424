
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //apiUrl: 'http://localhost:22900/api/',
  apiUrl: 'https://api.servispozitif.com/api/',
  
  sirketadi: 'POWERTECTR',
  sirketlogo: 'https://powertectr.com/images/Logo/big/ec78a59a-2a88-474d-808d-265b39137f0e.png',
  sirketadres:"OSTİM VİZYON İŞYERLERİ SERHAT MAH. 1147 Cad. No: 16 - ANKARA",
  sirkettel:"+90 850 380 24 39",
  sirketemail:"teknikservis@powertectr.com",
  website:"https://powertectr.com/",
  instagram:"https://www.instagram.com/powertec.tr/",
  facebook:"#",
  apikey:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJLRVkiOnsia3VydW1hcGlrZXkiOiIzNTg4Rjk0MC05OTBELTQ2OEYtQTIwQS1CRUI3RDlCMDNDNzgiLCJzZXJ2aXNuYW1lIjoiQ0lIQVpTT1JHVSJ9fQ.V3Y2s0CYXPkBpzxjGIP3Vr985J95l9J0BydB48FPcBg',

};
