<section class="ftco-section">
  <div class="container" *ngIf="!dataModel">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10">
        <div class="wrap d-md-flex">
          <div
            class="
              text-wrap
              p-4 p-lg-5
              text-center
              d-flex
              align-items-center
              order-md-last
            "
          >
            <div class="text w-100">
              <h2>{{sirketadi}}</h2>
              <p>Adres:{{sirketadres}}</p>
              <p>Telefon: {{sirkettel}}</p>
              <p>Email: {{sirketemail}}</p>
            </div>
          </div>
          <div class="login-wrap p-4 p-lg-5">
            <div class="w-100">
              <h3 class="text-center">Servis Kayıt Sorgula</h3>
            </div>
            <div class="d-flex">
              <div class="w-100">
                <p class="social-media d-flex justify-content-end">
                  <a
                    href="{{website}}"
                    target="_blank"
                    class="
                      social-icon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    ><span class="fa fa-home"></span
                  ></a>
                  <a
                    href="{{facebook}}"
                    class="
                      social-icon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    ><span class="fa fa-facebook"></span
                  ></a>
                  <a
                    href="{{instagram}}"
                    class="
                      social-icon
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    ><span class="fa fa-instagram"></span
                  ></a>
                </p>
              </div>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="form-group mb-3">
                <label class="label" for="name">Kayıt No</label>
                <input
                  type="text"
                  formControlName="ServisNo"
                  class="form-control"
                  [(ngModel)]="servisno"
                  [ngClass]="{ 'is-invalid': submitted && f.ServisNo.errors }"
                />
              </div>
              <div class="form-group mb-3">
                <label class="label" for="password">Cep Telefonu</label>
                <input
                  type="text"
                  formControlName="TelefonNo"
                  class="form-control"
                  [(ngModel)]="phone"
                  [ngClass]="{ 'is-invalid': submitted && f.TelefonNo.errors }"
                />
              </div>
              <div class="form-group mb-3">
                <div recaptcha class="form__captcha">
                  <!-- See directive selector above -->
                </div>
              </div>

              <div class="form-group">
                <button
                  [disabled]="loading"
                  type="submit"
                  class="form-control btn btn-primary submit px-3"
                >
                  <span
                    *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"
                  ></span>
                  Sorgula
                </button>
              </div>
              <div class="form-group d-md-flex">
                <div class="w-100 text-left">
                  <p class="text-center">
                    <a href="https://www.servispozitif.com/" class="label"
                      >Servis Pozitif</a
                    >
                   Şirketi tarafından hizmet verilmektedir.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="dataModel">
    <div class="header clearfix">
      <nav>
        <ul class="nav nav-pills pull-right">
          <li role="presentation" class="active">
            <a href="{{website}}">Ana Sayfa</a>
          </li>
        </ul>
      </nav>
      <img style="width: 200px" src="{{sirketlogo}}" />
    </div>

    <div class="marketing">
      <div class="col-lg-12">
        <div class="centre_box status_box" id="result">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="text-center my-4">
                      <h2>Kaydı Bilgileri</h2>
                    </div>
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <b>Talep</b>
                            <br />
                            <div class="label">{{ dataModel.TalepBaslik }}</div>
                          </td>
                          <td>
                            <b>Marka/Model</b>
                            <br />
                            <div class="label">{{ dataModel.Markaname }}</div>
                          </td>
                          <td>
                            <b>Statü</b>
                            <br />
                            <div class="label">{{ dataModel.ServisAd }}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Garanti Durumu</b>
                            <br />
                            <div class="">{{ dataModel.GarantiDurum }}</div>
                          </td>
                          <td>
                            <b>Seri Numarası</b>
                            <br />
                            <div class="label">-</div>
                          </td>
                          <td>
                            <b>Stok Numarası</b>
                            <br />
                            <div class="label">-</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Kayıt Tarihi</b>
                            <br />
                            <div class="label">{{ dataModel.KayitTar }}</div>
                          </td>
                          <td colspan="2">
                            <b>Sonlanma Tarihi</b>
                            <br />
                            <div class="label">
                              {{ dataModel.Teslimattarihi ||" Süreç Devam Ediyor" }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Müşteri İsim</b>
                            <br />
                            <div class="label">{{ dataModel.Adsoyad }}</div>
                          </td>
                          <td>
                            <b>Müşteri Mail</b>
                            <br />
                            <div class="label">************</div>
                          </td>
                          <td>
                            <b>Müşteri Telefon</b>
                            <br />
                            <div class="label">*************</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b
                              >Müşterinin Yaptığı Açıklama / Bildirilen Arıza</b
                            >
                            <br />
                            <div class="label">
                              {{ dataModel.SikayetBilgileri }}
                            </div>
                          </td>
                          <td colspan="2">
                            <b>Yapılan İşlem</b>
                            <br />
                            <div class="label">
                              {{ dataModel.TamirBilgileri }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <b>Teslim Edilen Parçalar</b>
                            <br />
                            <div class="label">
                              {{ dataModel.Teslimedilenler || "-" }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="text-center">
                      <h4>İşlem Aşamaları</h4>
                    </div>
                    <div class="table-responsive mt-1">
                      <table
                        class="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"                      >
                        <thead>
                          <tr>
                            <th>Sıra</th>
                         
                            <th>İşlem Durumu</th>
                            <th>İşlem Tarihi</th>
                            <th>Ücret</th>
                            <th>Yapılan İşlem</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let  item of dataModel.list; let i =index" >
                            <td>{{ i + 1 }}</td>
                            <td>{{item.statu}}</td>
                            <td>{{item.islemtarihi}}</td>
                            <td>{{item.statu}}</td>
                            <td>{{item.yapilanislem}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12" style="text-align: center">
            <a
              href="/"
              class="form-control btn btn-primary submit px-3"
              role="button"
              aria-pressed="true"
              >Yeniden Sorgula</a
            >
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</section>

<footer class="site-footer">
  <div class="row justify-content-center">
   <a href="{{website}}"> <img style="width: 200px" src="{{sirketlogo}}" /></a>
  </div>
</footer>


